// Import the functions you need from the SDKs you need

import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBsAKsuXbFjDqfAEGgpfgsEK0z7UPd-OJE",
  authDomain: "payment-session.firebaseapp.com",
  databaseURL: "https://payment-session-default-rtdb.firebaseio.com",
  projectId: "payment-session",
  storageBucket: "payment-session.appspot.com",
  messagingSenderId: "16075070981",
  appId: "1:16075070981:web:ee24ed88a859a9fa09c66c"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Get a reference to the database service
const db = getDatabase(firebaseApp);

export default db;